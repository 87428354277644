import React from 'react'
import { ModalInfoTemplate } from '../../features/pages/order-food-online/components/templates/modal-info-template'

export const OrderProgramInfoModal = props => {
  const storedTitle = localStorage.getItem('selectedTitle')

  let titleContent = ''
  let programContent = ''

  switch (storedTitle) {
    case 'Standard':
      titleContent = 'Standard'
      programContent =
        'Zdrowa i w pełni zbilansowana dieta, bogata w niezbędne składniki mineralne, witaminy i błonnik pokarmowy. Opiera się na : świeżych warzywach i owocach; pełnoziarnistych produktach zbożowych; nabiale; chudym mięsie; rybach. Wzbogacana regularnie o sezonowe składniki, które cieszą się najlepszymi ocenami wśród Klientów. Komponując naszą kluczową dietę, posiłkujemy się wiedzą i badaniami zgodnymi z normami żywienia regulowanymi przez Instytut Żywności i Żywienia, pod okiem naszych Dietetyków. Dieta podzielona jest na warianty kaloryczne, które w zależności od założonego celu dietetycznego, pozwolą utrzymać aktualną masę ciała lub smacznie i zdrowo zredukować masę ciała.'
      break
    case 'Vege':
      titleContent = 'Vege'
      programContent =
        'Dieta skierowana do osób, która wykluczają spożywanie mięs i ryb w swoim jadłospisie. Składa się głownie z produktów zbożowych , nasion i roślin strączkowych , świeżych warzyw i owoców , produktów mlecznych , jaj i zdrowych tłuszczy. Dieta dostarcza organizmowi potrzebnych witamin, składników mineralnych oraz błonnika pokarmowego.'
      break
    case 'Sport':
      titleContent = 'Sport'
      programContent =
        'Nutrition Sport to dieta skierowana do osób aktywnych, uprawiających sport, ze zwiększonym zapotrzebowaniem na energię oraz podaż składników pokarmowych. Zbilansowane przez nas posiłki zawierają zwiększone ilości białka przy obniżonej zawartości tłuszczu. Jest to idealna propozycja dla osób aktywnych fizycznie.'
      break
    case 'Office':
      titleContent = 'Office'
      programContent =
        'Zestaw składa się z 3 posiłków - śniadanie , obiad i podwieczorek. Przygotowany z myślą o zapewnieniu zdrowego odżywiania w pracy.'
      break
    case 'Office Vege':
      titleContent = 'Office Vege'
      programContent =
        'Zestaw składa się z 3 posiłków - śniadanie , obiad i podwieczorek. Przygotowany z myślą o zapewnieniu zdrowego odżywiania w pracy.'
      break
    case 'BEZ GLUTENU i LAKTOZY':
      titleContent = 'BEZ GLUTENU i LAKTOZY            '
      programContent =
        'Dieta eliminująca produkty zawierające gluten i produkty mleczne. W naszym menu zastąpiliśmy je produktami zbożowymi naturalnie pozbawionymi glutenu oraz roślinnymi zamiennikami mleka. Program ten polecamy osobom mającym alergię lub nietolerancję pokarmową na mleko i jego przetwory, laktozę i gluten. Ze względu na brak możliwości całkowitej eliminacji glutenu program ten nie jest odpowiedni dla osób chorujących na celiakię.'
      break
    case 'WEGAŃSKA':
      titleContent = 'WEGAŃSKA'
      programContent =
        'Dieta wegańska eliminuje wszystkie produkty pochodzenia zwierzęcego takie jak: mięso, ryby, nabiał, jaja, miód. Dieta ta opiera się głównie na produktach roślinnych.'
      break
    case 'HASHIMOTO':
      titleContent = 'HASHIMOTO'
      programContent =
        'Dieta Hashimoto to plan odżywczy skomponowany dla osób ze zdiagnozowanym autoimmunologicznym zapaleniem tarczycy Hashimoto. Nasz program ma za zadanie wyciszyć procesy zapalne toczące się w organizmie, wspomóc leczenie farmakologiczne, dostarczyć odpowiednią ilość makroskładników, witamin i składników mineralnych wspierających prawidłowe funkcjonowanie organizmu. Dieta Hashimoto nie zawiera soi, glutenu i produktów mlecznych.'
      break
    case 'Ketogeniczna':
      titleContent = 'Ketogeniczna'
      programContent =
        'Dieta Ketogeniczna charakteryzuje się długotrwałym niskim spożyciem węglowodanów (poniżej 50g dziennie w zależności od kaloryczności diety). Jej podstawę stanowią tłuszcze i umiarkowane ilości białka.'
      break
    case 'LOW IG':
      titleContent = 'LOW IG'
      programContent =
        'Low Ig to dieta z niskim indeksem glikemicznym, Idealnie sprawdza się u osób cierpiących na insulinooporność oraz cukrzycę typu 2. Eliminuje produkty wysokoprzetworzone , cukier oraz produkty o wysokim indeksie glikemicznym .'
      break
    default:
      titleContent = 'Office Vege'
      programContent = ''
      break
  }

  return (
    <ModalInfoTemplate {...props}>
      <div>
        <div>
          <h3>{titleContent}</h3>
          <p>{programContent}</p>
        </div>
      </div>
    </ModalInfoTemplate>
  )
}
