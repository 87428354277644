import React from 'react';
import { Button2 } from '../button2';
import { useModalContext } from '../../shared/lib/react-context-modal';
import styles from './new-food-card-calendar.module.css'
import { OrderProgramInfoModal } from '../callInfoBlok';

export const NewFoodCardCalendar = ({ jidel, img, title, children, type, price }) => {
    const { showModal } = useModalContext();

    const handleCallMe = (title) => {
        switch (title) {
            case 'Standard':
                window.location.href = 'https://order.nutritionpro.pl/zamowienie/';
                break;
            case 'Vege':
                window.location.href = 'https://order.nutritionpro.pl/zamowienie/';
                break;
            case 'Sport':
                window.location.href = 'https://order.nutritionpro.pl/zamowienie/';
                break;
            case 'Office':
                window.location.href = 'https://order.nutritionpro.pl/zamowienie/';
                break;
            case 'Office Vege':
                window.location.href = 'https://order.nutritionpro.pl/zamowienie/';
                break;
            case 'BEZ GLUTENU i LAKTOZY':
                window.location.href = 'https://order.nutritionpro.pl/zamowienie/';
                break;
            case 'WEGAŃSKA':
                window.location.href = 'https://order.nutritionpro.pl/zamowienie/';
                break;
            case 'HASHIMOTO':
                window.location.href = 'https://order.nutritionpro.pl/zamowienie/';
                break;
            case 'LOW IG':
                window.location.href = 'https://order.nutritionpro.pl/zamowienie/';
                break;  
            case 'Ketogeniczna':
                window.location.href = 'https://order.nutritionpro.pl/zamowienie/';
            case 'DIETA Z WYBOREM MENU':
                window.location.href = 'https://order.nutritionpro.pl/zamowienie/'
                break;   

        }
    };

    const handleOrderFoodProgramModal = (title) => {
        showModal(OrderProgramInfoModal);
        localStorage.setItem('selectedTitle', title)

    };

    return (
        <div className={styles.foodCardCalendar}>
            <div className={styles.foodCardBody}>
                <div style={{ backgroundImage: `url(${img})`, borderRadius: "15px 15px 0 0", height: "200px" }}>{children}</div>
                <span className={styles.label}>{jidel}</span>
                <div className={styles.infoContainer}>
                    <h5 className={styles.foodCardTitle}>{title}</h5>
                    <h3 className={styles.description}>{type}</h3>
                   {
                   title !== 'DIETA Z WYBOREM MENU' &&   <h6 className={styles.about} onClick={() => handleOrderFoodProgramModal(title)}>
                     <a>Więcej</a>
                </h6>
                   }
                    {/* <h3 className={styles.price} style={{ fontSize: '14px', fontWeight: 'bold' }}> już od <b style={{ fontSize: '20px' }}> {price}</b><span> / dzień</span></h3> */}

                    <Button2
                        id={styles.secondBtn}
                        color="secondary"
                        variant="outline"
                        size={'sm'}
                        buttonType="button"
                        disabled={false}
                        className={styles.submitButton}
                        onClick={() => handleCallMe(title)}
                    >
                        Zamów online
                    </Button2>
                </div>
            </div>
        </div>
    );
};
